import React from "react"

import Header from "./header"
import styled from "@emotion/styled"
import Footer from "./footer"
import CleanAndSafe from "./clean-and-safe"

const LayoutMain = styled.main`
  height: 80vh;
`

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <LayoutMain>{children}</LayoutMain>
      <CleanAndSafe />
      <Footer />
    </>
  )
}

export default Layout
