import React from "react"
import styled from "@emotion/styled"
import { graphql, Link, useStaticQuery } from "gatsby"
import themeColors from "./theme-colors"
import Color from "color"

const LayoutHeader = styled.header`
  position: fixed;
  margin: 1rem 0;
  z-index: 1;
  width: 100%;

  > section {
    max-width: 1024px;
    display: flex;
    margin: 0 auto;

    @media (max-width: 1024px) {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  img {
    width: 7rem;
  }

  nav {
    flex: 1;
    display: flex;
  }

  ul {
    list-style: none;
    display: flex;
    flex: 1;
    justify-content: flex-end;

    a {
      text-decoration: none;
      display: inline-block;
      padding: 0.6em 0.8em;
      background: ${themeColors.backColor.inverse};
      color: ${themeColors.fontColor.inverse};

      :hover {
        background: ${themeColors.backColor.value};
        color: ${themeColors.fontColor.value};
      }
    }
  }

  li:first-of-type {
    margin-left: 0;
  }

  li {
    align-self: center;
    margin-left: 1rem;
    border-radius: 0.5em;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
    overflow: hidden;

    a:focus {
      outline-offset: -5px;
    }

    border: 1px solid ${themeColors.fontColor.value};
  }

  .active {
    background: ${Color(themeColors.backColor.value)
      .lighten(0.1)
      .string()};
    color: ${Color(themeColors.fontColor.value)
      .darken(0.1)
      .string()};
    font-weight: 600;
  }
`

const Header = () => {
  const { site, menuStructure } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          logo
        }
      }
      menuStructure: allMenusYaml {
        menus: nodes {
          id
          slug
          title
        }
      }
    }
  `)

  const { logo, title } = site.siteMetadata
  const { menus } = menuStructure

  const logoSrc = require(`../images/${logo}`)

  return (
    <LayoutHeader>
      <section>
        <Link to="/">
          <img src={logoSrc} alt={title} />
        </Link>
        <nav>
          <ul>
            {menus.map(({ id, title, slug }) => (
              <li key={id}>
                <Link to={slug} activeClassName="active" partiallyActive={true}>
                  {title}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </section>
    </LayoutHeader>
  )
}

export default Header
