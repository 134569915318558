import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "@emotion/styled"
import themeColors from "./theme-colors"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"

const Line = styled.section`
  display: grid;
  grid-template-areas: "phone email social";
  grid-template-rows: auto;
  grid-row-gap: 0.5rem;

  align-items: center;
  white-space: nowrap;

  @media (max-width: 400px) {
    grid-template-areas:
      "phone email"
      "social social";
    font-size: 0.9rem;
  }

  a {
    color: ${themeColors.fontColor.inverse};
    text-decoration: none;
    font-weight: 400;
    grid-area: phone;
  }

  a:nth-of-type(2) {
    justify-self: center;
    grid-area: email;
    @media (max-width: 400px) {
      justify-self: flex-end;
    }
  }
`

const SocialLink = ({ href, icon, title }) => (
  <a href={href} title={title} target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon icon={icon} size="2x" />
  </a>
)

const Social = styled.aside`
  grid-area: social;

  display: flex;
  justify-content: flex-end;

  a {
    margin-left: 0.5rem;
  }

  @media (max-width: 400px) {
    justify-content: center;

    a:first-of-type {
      margin-left: 0;
    }
  }
`

const ContactLine = () => {
  const {
    contact: {
      siteMetadata: { title, email, phoneNumber, facebookUrl, instagramUrl },
    },
  } = useStaticQuery(graphql`
    query {
      contact: site {
        siteMetadata {
          title
          phoneNumber
          email
          facebookUrl
          instagramUrl
        }
      }
    }
  `)

  return (
    <Line>
      <a
        href={`tel:${phoneNumber.replace(/\s/g, "")}`}
        title={`Call ${title} by ${phoneNumber}`}
      >
        {phoneNumber}
      </a>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`mailto:${email}?subject=Desejo receber mais informações`}
        title={`Mail to ${title} using ${email}`}
      >
        {email}
      </a>

      <Social>
        <SocialLink
          href={instagramUrl}
          title={`Instagram page ${title}`}
          icon={faInstagram}
        />
        <SocialLink
          href={facebookUrl}
          title={`Facebook page ${title}`}
          icon={faFacebookSquare}
        />
      </Social>
    </Line>
  )
}

export default ContactLine
