import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "@emotion/styled"

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & div {
    top: -20px;
    width: 100px;
  }

  & blockquote {
    margin-top: 10px;
  }
`

const CleanAndSafe = () => {
  const {
    file,
    site: {
      siteMetadata: { legal },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          legal
        }
      }
      file(relativePath: { eq: "clean-and-safe.png" }) {
        childImageSharp {
          fluid(maxWidth: 240) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Center>
      {/*<Img loading="auto" fluid={file.childImageSharp.fluid} />*/}
      <blockquote>{legal}</blockquote>
    </Center>
  )
}

export default CleanAndSafe
