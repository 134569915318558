import styled from "@emotion/styled"
import React from "react"
import themeColors from "./theme-colors"
import ContactLine from "./contact-line"
import { graphql, useStaticQuery } from "gatsby"

const FixedFooter = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: ${themeColors.backColor.inverse};

  > section {
    margin: 0 auto;
    max-width: 1024px;

    @media (max-width: 1024px) {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    padding: 1rem 0;
  }
`

const BottomFooter = styled.footer`
  background: ${themeColors.backColor.value};
  color: ${themeColors.fontColor.value};
  height: 8rem;
  margin-top: 20vh;

  > section {
    margin: 0 auto;
    max-width: 1024px;
    font-size: 0.9rem;
    display: flex;

    & p:first-child {
      flex: 1;
    }

    & a {
      text-decoration: none;
      color: whitesmoke;
    }

    @media (max-width: 400px) {
      flex-direction: column;

      & p {
        text-align: center;
      }

      & p:first-child {
        order: 2;
      }
      & p:last-child {
        padding-bottom: 0.8em;
        order: 1;
      }
    }

    @media (max-width: 1024px) {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    padding: 1rem 0;

    span {
      font-size: 0.6rem;
      font-weight: 100;
    }
  }

  @media (max-width: 400px) {
    height: 10rem;
  }
`
const Footer = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <FixedFooter>
        <ContactLine />
      </FixedFooter>
      <BottomFooter>
        <section>
          <p>
            © {new Date().getFullYear()} {title} <span>by hpa</span>
          </p>
          <p>
            <a
              href="https://www.livroreclamacoes.pt/inicio"
              rel="noopener noreferrer"
              target="_blank"
            >
              Livro de Reclamações Digital
            </a>
          </p>
        </section>
      </BottomFooter>
    </>
  )
}
export default Footer
