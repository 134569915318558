import globalColors from "../../global-colors"

const themeColors = {
  backColor: {
    value: globalColors.background,
    inverse: globalColors.color,
  },
  fontColor: {
    value: globalColors.color,
    inverse:  globalColors.background,
  },
}

export default themeColors
